.transition-appear {
  opacity: 0.01;
}
.transition-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-enter {
  opacity: 0.01;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 3000ms ease-in;
}

.transition-leave {
  opacity: 0.01;
}

.transition-leave.transition-leave-active {
  opacity: 0.01;
  transition: opacity 3000ms ease-in;
}
